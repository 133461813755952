import React from "react";
import { graphql } from "gatsby";
// import Blog from "./blog";
import BlogElem from "../components/blog/blog";
import Content from "../components/utilities/content";
import CmsImage from "../components/utilities/cms-image";
import HeadingTag from "../components/utilities/heading-tag";
import Seo from "../components/global/seo";
import IpTracker from "../components/utilities/ip-tracker";

const Post = ({
  data: {
    wpPost: post,
    wp: {
      themeSettings: { themeSettings },
    },
  },
}) => {
  return (
    <>
      <Seo
        title={post.title}
        description={post.page.metaDescription}
        themeSettings={themeSettings}
        image={post.featuredImage?.node.localFile.publicURL}
      />
      <BlogElem>
        {post.featuredImage && !post.thumbnail?.hideFeaturedImage && (
          <CmsImage image={post.featuredImage.node} className="p-3" />
        )}
        <div className="mt-5">
          <HeadingTag className="post-title">{post.title}</HeadingTag>
          <Content className="content-blog">{post.content}</Content>
        </div>
        {post.astroturfTv.astroturfTvEmbed && (
          <div className="my-5">
            <div className="ratio ratio-16x9">
              <iframe
                width="560"
                height="315"
                src={post.astroturfTv.astroturfTvEmbed}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        )}
      </BlogElem>
      <IpTracker />
    </>
  );
};

export const pageQuery = graphql`
  query Post($id: String!) {
    wp {
      themeSettings {
        themeSettings {
          siteMetaTitle
          customCssOverrides
          logo {
            localFile {
              publicURL
            }
          }
        }
      }
    }

    wpCategory(posts: { nodes: { elemMatch: { id: { eq: $id } } } }) {
      posts {
        nodes {
          id
          title
          uri
          excerpt
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
                extension
                publicURL
              }
            }
          }
          astroturfTv {
            fieldGroupName
            astroturfTvEmbed
          }
          projects {
            fieldGroupName
            projectCategory
          }
        }
      }
    }

    wpPost(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            extension
            publicURL
          }
        }
      }
      astroturfTv {
        astroturfTvEmbed
      }
      page {
        fieldGroupName
        metaTitle
        metaDescription
      }
      thumbnail {
        hideFeaturedImage
      }
    }
  }
`;

export default Post;
